import React, {FC} from 'react';
import style from './AccordionContent.module.scss';
import {useTranslation, Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {AccountRoutesPathEnum, MainRoutesEnum} from 'core';
import {InternalLinksEnum} from "../../../../typings";


export const AccordionContent: FC<{contentKey: string}> = (props) => {
    const {contentKey} = props;
    const {t} = useTranslation();

    return (
        <>
            {contentKey === 'whatIsExtension' &&
              <>
                <p>{t('Giftmio extension is a Google Chrome extension that will')}:</p>
                <ul className={style.list}>
                  <li>{t('reming you of the opportunity to get your cashback')};</li>
                  <li>{t('simplify cashback activation')}.</li>
                </ul>
                <b>{t('How to use the Giftmio extension')}:</b>
                <ol className={style.list}>
                  <li>{t('Go to Chrome store, isntall and pin the extension')}.</li>
                  <li>{t('Sign up or login to Giftmio extension. If you already have a Giftmio account, you just need to sign in')}.</li>
                  <li>{t('Shop as usual and get notified once a cashback opportunity is available')}.</li>
                  <li>{t('Activate cashback in one click via extension')}.</li>
                  <li>{t('Complete the purchase and see the cashback credited to your account')}.</li>
                </ol>
                <p>
                  <Trans i18nKey='faqs_extension_learn_more'>Learn more: <a href={InternalLinksEnum.EXTENSION_LANDING} target="_blank">extension.giftmio.com</a>.</Trans>
                </p>
              </>
            }
            {contentKey === 'howWorks' &&
              <>
                <p
                  className={style.paragraph}>{t(`Welcome to Giftmio, where shopping meets savings! We're here to help you get a portion of your money back with each purchase. Here's how it effortlessly works: `)}</p>
                <p className={style.paragraph}>
                  <Trans i18nKey='faqs_howWorks_Extension'><b>Install <a href={InternalLinksEnum.EXTENSION_LANDING} target="_blank">Giftmio Chrome Extension</a>.</b> It will remind you of the opportunity to get your cashback and
                    simplify cashback activation.</Trans>
                </p>
                <p className={style.paragraph}>
                  <Trans i18nKey='faqs_howWorks_SignUp'><b>Sign Up:</b> Begin by creating a free Giftmio account — your
                    gateway to rewarding shopping experiences.</Trans>
                </p>
                <p className={style.paragraph}>
                  <Trans i18nKey='faqs_howWorks_Brows&Shop'><b>Browse and Shop:</b> Navigate through our extensive array
                    of partner retailers. Whether it's a store you frequently visit or a new discovery, we're here to
                    connect you with the best deals.</Trans>
                </p>
                <p className={style.paragraph}>
                  <Trans i18nKey='faqs_howWorks_MakePurchase'><b>Make a Purchase:</b> Go ahead and fill your cart with
                    things you love. Just ensure to initiate your shopping journey through the Giftmio platform to be
                    eligible for cashback.</Trans>
                </p>
                <p className={style.paragraph}>
                  <Trans i18nKey='faqs_howWorks_AdBlocker:'><b>Ad-Blocker Notice:</b> Before you dive too deep, here's a
                    little reminder: To ensure the correct accrual of cashback, please add our site to your browser's
                    ad-blocker exceptions. It's a vital step!</Trans>
                </p>
                <p className={style.paragraph}>
                  <Trans i18nKey='faqs_howWorks_EarnCashback'><b>Earn Cashback:</b> After completing your purchase, a
                    percentage of the amount you spent comes right back to your Giftmio account as cashback. The
                    percentage might fluctuate between retailers, but it promises to be a win-win.</Trans>
                </p>
                <p className={style.paragraph}>
                  <Trans i18nKey='faqs_howWorks_WithdrawEarnings'><b>Withdraw Your Earnings:</b> Once you've accumulated
                    a decent stash of cashback rewards, you can withdraw them through a method that suits you best, be
                    it a bank transfer, PayPal, or gift cards</Trans>
                </p>
                <p>
                  <Trans i18nKey='faqs_howWorks_EnjoySavings'><b>Enjoy Your Savings:</b> Now, it's time to enjoy the
                    fruits of your smart shopping! Use your cashback earnings however you like — save it or gift
                    yourself something special! Remember, the secret to boosting your cashback savings is to regularly
                    check Giftmio for the newest deals and promotions from your favorite retailers. Happy
                    shopping!</Trans>
                </p>
              </>
            }
            {contentKey === 'howReceive' &&
              <>
                <p
                  className={style.paragraph}>{t(`At Giftmio, we aim to process your cashback as swiftly as possible. Here's how the process flows:`)}</p>
                <p className={style.paragraph}>
                  <Trans i18nKey='faqs_howReceive_OrderConfirmation'><b>Order Confirmation:</b> Firstly, in order to reward you with your well-deserved cashback, we need to receive the payment for your order from the respective retailer.</Trans>
                </p>
                <p className={style.paragraph}>
                  <Trans i18nKey='faqs_howReceive_RetailerApproval'><b>Retailer Approval:</b> The retailers usually take a bit of time to confirm the order. On average, this can take several weeks, as they also need to check that no product returns are being made.</Trans>
                </p>
                <p>
                  <Trans i18nKey='faqs_howReceive_CashbackCrediting'><b>Cashback Crediting:</b> As soon as we receive confirmation and payment from the retailer, we promptly credit the cashback to your Giftmio account, making it available for you to withdraw and enjoy.</Trans>
                </p>
              </>
            }
            { contentKey === 'howMakePurchases' &&
              <>
                <p className={style.paragraph}>{t(`To make sure you always get your cashback when shopping through Giftmio, here's what you need to keep in mind:`)}</p>
                <h4 className={style.sub_title}>{t(`1. Follow the Store's Rules`)}</h4>
                <p className={style.paragraph}>{t(`Each store has its own set of rules concerning cashback. It's a good idea to check these rules on the store's page on our Giftmio platform before you start shopping. Sometimes, different product categories within a store may offer different cashback rates. And keep in mind, certain stores may not provide cashback for purchases made using specific promo codes. A quick glance at the store's rules on Giftmio can help you avoid missing out on cashback.`)}</p>
                <h4 className={style.sub_title}>{t(`2. Use Our Tracking Link for Purchases`)}</h4>
                <p className={style.paragraph}>{t(`Ensuring your cashback is simple - always use the tracking link from our catalog when making a purchase. This way, we can track your orders and guarantee your cashback. Here are a few more tips to ensure a smooth experience:`)}</p>
                <ul className={style.paragraph}>
                  <li>
                    <Trans i18nKey='faqs_howMakePurchases_LoginGiftmio'><b>Log in to Giftmio:</b> Make sure you're logged into your Giftmio account before you start shopping.</Trans>
                  </li>
                  <li>
                    <Trans i18nKey='faqs_howMakePurchases_AvoidIncognitoMode'><b>Avoid Incognito Mode:</b>  Try not to use incognito mode or browsers with high privacy settings (like Brave) when shopping. If needed, switch to a more mainstream browser (like Chrome) to complete your purchase.</Trans>
                  </li>
                  <li>
                    <Trans i18nKey='faqs_howMakePurchases_Purchase'><b>Complete Purchase in One Visit:</b> Aim to finish your purchase in one go. Saving your shopping basket to complete at a later date can cause your cashback to be declined as too much time has passed for the referral to be paid to us.</Trans>
                  </li>
                  <li>
                    <Trans i18nKey='faqs_howMakePurchases_Whitelist'><b>Whitelist Our Site:</b> Remember to add Giftmio to your ad-blocker's whitelist and check for other browser extensions that might block the tracking of your orders.</Trans>
                  </li>
                </ul>
                <p>{t(`Following these guidelines, you'll be well on your way to a smooth and rewarding cashback experience with Giftmio.`)}</p>
              </>
            }
            { contentKey === 'didntReceive' &&
              <>
                <p className={style.paragraph}>
                  <Trans i18nKey='faqs_didntReceive_description'>We are here to help you track down your cashback! In case of missing cashback, we will initiate a request to the store, and if they confirm it, your cashback will be credited to your account. To facilitate this, you need to <a href="mailto:support@giftmio.com?subject=I've%20received%20no%20cashback" target="_new">submit a request</a>.</Trans>
                </p>
                <p className={style.paragraph}>{t(`In your email, please ensure to provide the following details:`)}</p>
                <ul className={`${style.paragraph} ${style.list_decimal}`}>
                  <li>{t(`The store where you made the purchase`)}</li>
                  <li>{t(`The date and time of the purchase (can be found on the receipt)`)}</li>
                  <li>{t(`The order number (refer to the confirmation email from the store or the receipt)`)}</li>
                  <li>{t(`The total amount and currency of the order`)}</li>
                  <li>{t(`Attach a photo of the receipt or a screenshot from your personal account on the store's website displaying the order number, date, amount, status, and contents. Please ensure the file format is in jpg, jpeg, or png.`)}</li>
                </ul>
                <p>{t(`We are committed to ensuring that your cashback reaches you. Once we receive the required details, we will promptly investigate and strive to resolve the issue for you.`)}</p>
              </>
            }
            { contentKey === 'withdrawMoney' &&
              <>
                <p className={style.paragraph}>
                  <Trans i18nKey='faqs_withdrawMoney_description'>Withdrawing your earned cashback is easy! To request a withdrawal, simply navigate to the "Withdrawal" section in your account, or follow this <Link to={`${MainRoutesEnum.ACCOUNT}${AccountRoutesPathEnum.WITHDRAWAL}`}>link</Link>.</Trans>
                </p>
                <p className={style.paragraph}>{t(`Currently, you can transfer your earnings to your PayPal account. Stay tuned as we are working on expanding our payout options to include bank transfers or as gift cards to your favorite stores - more convenience is on the way!`)}</p>
                <p className={style.paragraph}>{t(`Please note the following when you are ready to withdraw:`)}</p>
                <ul>
                  <li>{t(`Minimum Withdrawal Amount: You can start withdrawing once your balance reaches 10 USD or its equivalent in other currencies.`)}</li>
                  <li>{t(`Maximum Withdrawal Limit: Keep in mind, the maximum amount you can withdraw in a calendar month is limited to 300 USD or its equivalent in other currencies.`)}</li>
                  <li>{t(`Payment period. Cashback will be available for withdrawal in 14-90 days after purchase.`)}</li>
                </ul>
              </>
            }
            { contentKey === 'bonusPaymentConditions' &&
              <ul>
                <li>{t(`You'll receive a referral bonus in your Giftmio account once your referred contact has accumulated $10 in 'confirmed' cashback via Giftmio within 90 days after registration. This amount can be distributed across multiple transactions.`)}</li>
                <li>{t(`If eligible, your referred contact will also receive their corresponding referral bonus in their Giftmio account at this time.`)}</li>
                <li>{t(`To qualify for the referral bonus, your friend must enroll through the unique referral link you provide.`)}</li>
                <li>{t(`The Giftmio referral bonus can go up or down. You'll get the bonus amount that was shown on the website when your friend joined using your link. This amount might be different from what was offered when you shared the link or when the bonus was added to your account.`)}</li>
                <li>{t(`We are generous, but within limits. Anyone found creating multiple accounts to exploit the referral program will have all associated Giftmio accounts terminated.`)}</li>
              </ul>
            }
            { contentKey === 'isGiftmioFree' &&
              <p>{t(`Yes, it's completely free! You don't have to pay anything for registration, and we don't charge any commissions either.`)}</p>
            }
            { contentKey === 'howCashbackEarn' && <p>
              <Trans i18nKey='faqs_howCashbackEarn'>First, sign up with Giftmio, which you can do right on this page. Then, visit <Link to={`/`}>giftmio.com</Link> and choose the store where you want to shop. Giftmio partners with over 30,000 stores worldwide. When you shop at a store via a link from Giftmio and make a purchase, the store pays a referral fee. Giftmio then shares a portion of this fee with you as cashback.</Trans>
            </p>
            }
            { contentKey === 'doesGiftmioWorksMobile' && <p>
              <Trans i18nKey='faqs_doesGiftmioWorksMobile'>Yes, it does, as long as you're shopping on the store's website and not through a mobile app. Remember to access the store through the link on  <Link to={`/`}>giftmio.com</Link></Trans>
            </p>
            }
            { contentKey === 'whyGiftmioBetter' &&
              <p>{t(`With partnerships with over 30,000 stores globally, you can check out the vast options yourself by searching on the Giftmio website!`)}</p>
            }
            </>
    );

};
