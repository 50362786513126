import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDocumentTitle, useMedia} from 'hooks';
import style from './DirectLanding.module.scss';
import {Button, ModalHint} from 'components';
import {LandingTypes} from '../../typings';
import {setLandingType} from '../../landing.reducer';
import {useDispatch, useSelector} from 'react-redux';
import {useLandingMain} from '../../hooks';
import {RootState} from 'core';
import {ReactComponent as ChromeIcon} from 'assets/images/icons/chrome.svg';

const DirectLandingV1: FC = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const isMobile = useMedia();
    const [showHint, setShowHint] = useState<boolean>(true);
    const {handleCancelClick, handleRegistrationClick} = useLandingMain();
    const {uriParams} = useSelector((state: RootState) => state.landingReducer);

    useDocumentTitle(uriParams.domain || t('External website landing'));

    useEffect(() => {
        dispatch(setLandingType(LandingTypes.DIRECTV1));
    }, [dispatch]);


    const handleHintClose = () => {
        setShowHint(false);
    };

    return (
        <div className={style.landing}>
            <div className={style.content}>
                <p className={`textBodyMedium_L ${style.brand_text}`}>{t('Taking you to')}:</p>
                <div className={style.content_loader}>
                    <h1 className={`${isMobile ? 'textHeader_04' : 'textHeader_01'}`}>{uriParams?.domain || t('External website')}</h1>
                    <span className={style.loader}></span>
                </div>
                <p className={style.content_text}>{uriParams?.txt || t('Want to save up to 10%?')}</p>
                <div>
                    <p className={`textBodyMedium_01 ${style.cashbackText}`}>{t('Never miss a cashback opportunity while shopping online')}</p>
                    <div className={style.content_btns}>
                        <Button onClick={handleCancelClick} theme='stroke' textContent={t('No, I hate saving')}
                                kind='ellipse'/>
                        <Button onClick={() => handleRegistrationClick(true)}
                                IconLeft={ChromeIcon}
                                isIconAbsolute={true}
                                textContent={t('Yes, add Chrome extension')}
                                kind='ellipse'/>
                    </div>
                </div>
                {showHint && <ModalHint onCloseClick={handleHintClose} onOkClick={handleRegistrationClick}/>}
            </div>
        </div>
    );
};

export {DirectLandingV1};
export default DirectLandingV1;
